import React from "react";
import { Col, Row } from "react-bootstrap";
import HeaderImage from "../../assets/images/Home/TopHeader/header_img.png";
import GoogleStore from "../../assets/images/Home/TopHeader/google_store.png";
import AppleStore from "../../assets/images/Home/TopHeader/apple_store.png";

const AddHeader = () => {
  return (
    <div className="add-header-component">
      <div className="container">
        <Row>
          <Col className="head-data mt-5" xs={12} md={12} lg={6}>
            <h1 className="speak-up">Lorem Ipsum is simply dummy printing</h1>
            <p className="bottom-speak">
              Cum et convallis risus placerat aliquam, nunc. Scelerisque aliquet
              faucibus tincidunt eu adipiscing sociis arcu lorem porttitor Cum
              et convallis risus placerat aliquam, nunc. Scelerisque
            </p>
            <div className=" store_image">
              <div>
                <img loading="lazy"src={AppleStore} alt="" />
              </div>
              <div>
                <img loading="lazy"src={GoogleStore} alt="" />
              </div>
            </div>
          </Col>
          <Col
            className="ban-img d-none d-lg-block mt-5"
            md={{ span: 5, offset: 1 }}
          >
            <div className="vertical-img image-fluid">
              <img
                src={HeaderImage}
                className="image-fluid w-100 object-fit-contain vertical-image-css"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export { AddHeader };
