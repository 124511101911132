import React from "react";
import { Col, Row } from "react-bootstrap";
import mobileScreen from "../../assets/images/Home/Myprofile/myprofile.png";
import GoogleStore from "../../assets/images/Home/TopHeader/google_store.png";
import AppleStore from "../../assets/images/Home/TopHeader/apple_store.png";

const MyProfileImage = () => {
  return (
    <div className="create-post-feature-component container" id="about-us">
      <Row>
        <Col md={12} lg={6} className="d-flex align-items-center p-5">
          <div className="content">
            <h1>
              My Profile & <span>Add Image</span>
            </h1>
            <p className="mt-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic
            </p>
            <div className="d-flex justify-content-between w-100 mt-1 store_image">
              <div>
                <img
                  src={AppleStore}
                  alt=""
                  className="object-fit-contain image-fluid"
                />
              </div>
              <div>
                <img
                  src={GoogleStore}
                  alt=""
                  className="object-fit-contain image-fluid"
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} lg={6} className="add-image-column">
          <img
            src={mobileScreen}
            className="w-100 object-fit-contain image-fluid add-image"
          />
        </Col>
      </Row>
    </div>
  );
};

export default MyProfileImage;
